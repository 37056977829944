var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("section", { staticClass: "card" }, [
        _c("header", { staticClass: "card-header" }, [
          _c("h2", { staticClass: "card-title" }, [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "form",
            {
              staticClass: "form-horizontal form-bordered",
              attrs: { action: "#" },
            },
            _vm._l(_vm.fields, function (field) {
              return _vm.canBeShown(field)
                ? _c("input-loader", {
                    key: _vm.getKey("field", field.id),
                    ref: _vm.getKey("field", field.id),
                    refInFor: true,
                    attrs: { field: field, type: field.type },
                  })
                : _vm._e()
            }),
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }