var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.field.label) } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }