var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.component
    ? _c(_vm.component, {
        tag: "component",
        attrs: { field: _vm.field, type: _vm.type },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }